<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("Currency.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      :placeholder="$t('Currency.search')"
                      v-model="searchItem" @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("Currency.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listCurrency.length >0">
          <thead>
          <tr>
            <th class="text-left">
              {{ $t("Currency.table.currencyName") }}
            </th>
            <th class="text-left">
              {{ $t("Currency.table.currencyCode") }}
            </th>
            <th class="text-left">
              {{ $t("Currency.table.created") }}
            </th>
            <th class="text-left">
              {{ $t("Currency.table.updated") }}
            </th>
            <th class="text-left">

            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(item,idx) in listCurrency" :key="idx"
          >
            <td>{{ item.name }}</td>
            <td>{{ item.short }}</td>
            <td>{{ (item.created_at) }}</td>
            <td>{{ (item.updated_at) }}</td>
            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-edit" @click="onEdit(item)">Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <default-table-no-result v-else/>
        <Pagination
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchCurrency"
        >
        </Pagination>
        <Loading v-if="isLoading"/>
        <modalDeleteCurrency :visible="modalDeleteCurrency"
                             :currencyId="currency_id"
                             @success="fetchCurrency"
                             @change="(val) => modalDeleteCurrency = val"/>

      </div>
    </div>
  </div>
</template>

<script>
import modalDeleteCurrency from "@/components/currency/modal/modalDeleteCurrency";
import Pagination from "@/components/Paginate/Pagination";
import Loading from "../../../components/Loading";
import defaultTableNoResult from "../../../components/defaultTableNoResult";


export default {
  components: {
    modalDeleteCurrency,
    Pagination,
    Loading,
    defaultTableNoResult
  },

  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 8,

      modalDeleteCurrency: false,
      currency_id: "",
      listCurrency: [],
      isLoading: false,
    }
  },
  methods: {
    searchFilterItem() {
      this.fetchCurrency();
    },
    onCreate() {
      this.$router.push({name: 'currency.create'}).catch(() => {
      });
    },

    onEdit(item) {
      this.$store.dispatch('Currency/filterCurrency', item)
      this.$router.push({name: 'currency.edit', params: {id: item.id}}).catch(() => {
      })
    },
    onDeleted(currency_id) {
      this.currency_id = currency_id;
      this.modalDeleteCurrency = true;
    },

    fetchCurrency() {
      this.isLoading = true;
      this.$axios.get(`admin/currency`, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          filter: this.searchItem,

        }
      }).then((res) => {
        if (res.data.code === 200) {
          this.listCurrency = res.data.data.data;
          this.pagination = res.data.data.pagination;
          if (!this.listCurrency.length > 0) {
            this.pagination.current_page = this.pagination.current_page - 1;
          }
        }
        this.isLoading = false;
      })
    }
  },
  created() {
    this.fetchCurrency();
  }
}
</script>

<style scoped lang="scss">

</style>
